<template>
    <template v-if="!props.hasHtmlTags">
        {{ getTrans(props.translationKey, props.fallback, props.replacements, props.isPlural) }}
    </template>
    <!-- eslint-disable vue/no-v-html -->
    <span v-else
          v-html="getTrans(props.translationKey, props.fallback, props.replacements, props.isPlural)" />
    <!-- eslint-enable -->
</template>

<script setup lang="ts">
    import { getTrans } from './getTrans';

    const props = defineProps({
        translationKey: {
            type: String,
            required: true,
        },
        fallback: {
            type: String,
            default: '',
        },
        replacements: {
            type: Object,
            default: () => ({}),
        },
        isPlural: {
            type: Boolean,
            default: false,
        },
        hasHtmlTags: {
            type: Boolean,
            default: false,
        },
    });
</script>
